/*-----------------------------
    GENERAL
-------------------------------*/
@import 'design/template/mixin_templates';
@import 'design/variables';
@import url('https://fonts.googleapis.com/css?family=Amaranth:400,700');

@font-face {
  font-family: 'droid_sansregular';
  src: url('../images/imgd564series/fonts/DroidSans-webfont.eot');
  src: url('../images/imgd564series/fonts/DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
  url('../images/imgd564series/fonts/DroidSans-webfont.woff') format('woff'),
  url('../images/imgd564series/fonts/DroidSans-webfont.ttf') format('truetype'),
  url('../images/imgd564series/fonts/DroidSans-webfont.svg#droid_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@include titreBaseSize();

h2, .h2, h1, .h1 {
  margin: 15px 0;
  padding: 10px;

  &.title_section {
    padding: 10px;
  }
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;

  /* Pour tinyMCE */
  height: auto !important;
}

iframe {
  max-width: 100%;
}

body #minisite-sidebar.pos-left {
  left: 300px;
  z-index: 100;
}

#main-conteneur {
  width: 100%;
}

.button, .bouton, .links_p .bouton, .links_p .button, .produits .addbasket, .links_p a.addmsglo, .btn-search, input[type="submit"], .form-newsletter input[type="submit"], .addmsglo, .links_p .addmsglo {
  font-size: 1em;
  font-weight: 700;
  width: auto;
  display: inline-block;
  text-decoration: none;
  margin: 10px auto;
  vertical-align: middle;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 -4px rgba(0, 0, 0, 0.3) inset;
}

.modif-design #outils {
  z-index: 10000;
}

.page,
.template_boutique .boutique_header,
.template_boutique .boutique_footer,
.album-photo .galerie_header,
.album-photo .galerie_footer {
  box-sizing: border-box;
  box-shadow: 0 0 10px #444;
  padding: 15px;
  margin: 15px 0;
}

/*-----------------------------
    CSS pour le scroll menu
-------------------------------*/

/* user-select polyfill for IE */
[unselectable=on] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.scrollX,
.scrollY {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  > .viewport {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    > .content {
      box-sizing: content-box;
      min-height: 0;
    }
  }
  > .scrollbarY {
    top: 0;
    bottom: 0;
    right: 0;
    width: 5pt;
  }
  > .scrollbarX {
    bottom: 0;
    left: 0;
    right: 0;
    height: 5pt;
  }
  > .scrollbarY, .scrollbarX {
    position: absolute;
    opacity: 0;
    border-radius: 0;
    cursor: pointer;
    z-index: 2;
    margin: 0;
    background: #dcdcdc;
    transition: opacity ease-in-out 150ms, background ease-in-out 150ms;
    overflow: hidden;
    &.dragScrolling {
      opacity: 1;
    }
    &.disabled {
      display: none;
    }
    > .thumb {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background: #767676;
      background: rgba(118, 118, 118, 0.75);
      transition: background ease-in-out 150ms;
    }
    &:hover, &.dragScrolling {
      z-index: 2;
      > .thumb {
        background: #434343;
        background: rgba(67, 67, 67, 0.75);
      }
    }
  }
  &:hover {
    > .scrollbarY, .scrollbarX {
      opacity: 1;
    }
  }
}

.scrollX.initialized > .viewport {
  overflow-x: scroll;
}

.scrollY.initialized > .viewport {
  overflow-y: scroll;
}

.scrollX.scrollY > .scrollbarX {
  margin-right: 10pt;
}

.scrollX.scrollY > .scrollbarY {
  margin-bottom: 10pt;
}

/*-----------------------------
    CORPS
-------------------------------*/
body {
  background-repeat: repeat;
  margin: 0;
  font-family: 'droid_sansregular', helvetica, arial, sans-serif;
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  &#tinymce {
    display: block;
    align-items: inherit;
  }

  .wrapper-content, footer {
    width: calc(100% - 300px);
    float: right;
  }
}

#wrapper {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.content {
  min-height: 400px;
  // padding: 20px 20px 43px;
}

.row-fluid {
  max-width: 1280px;
  margin: 0 auto;
}

#wrapper > .row-fluid {
  width: 90%;
  margin: 0 auto;
}

.content {
  min-height: 400px;
}

/*-----------------------------
    HEADER
-------------------------------*/

.cmonsite-panier-3 #main-conteneur header {
  position: fixed;

  .menu {
    margin-top: 70px;
  }
  .scrollMenu {
    height: calc(100vh - 370px);
  }
}

header {
  position: fixed;
  height: 100%;
  width: 300px;
  z-index: 101;
  float: left;
  box-shadow: 0 0 10px #444;

  #headercontent {
    margin-bottom: 15px;
    height: 300px;
    text-align: center;
  }

  .logo {
    position: relative;
    display: inline-block;
    margin: 15px auto;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 150px;
    }
  }

  .slogan {
    font-family: 'Amaranth', sans-serif;
    font-size: 24px;
    width: 100%;
    text-align: center;
    padding: 0 15px;
    margin: 15px 0;
    box-sizing: border-box;
  }
}

/*-----------------------------
    MENU
-------------------------------*/

.scrollMenu {
  height: calc(100vh - 315px);
  nav#nav-principal {
    margin-bottom: 15px;
  }
}

nav#nav-principal {
  margin-right: 15px;
  width: 100%;

  > ul {
    clear: both;
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;
    margin: 10px 0;

    > li {
      display: block;
      position: relative;
      list-style: none outside none;
      width: 100%;
      z-index: 2000;

      &:hover > ul, &.actif > ul {
        display: block;
        visibility: visible;
      }

      a {
        display: block;
        font-family: 'Amaranth', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 2;
        padding: 0 24px;
        text-decoration: none;
      }
    }
  }
}

nav#nav-principal ul li:hover > ul {
  display: block;
}

nav#nav-principal ul li ul li {
  display: block;
  position: relative;
  z-index: 1;

  a {
    padding: 0;
    line-height: 2;
  }
}

nav#nav-principal ul ul {
  display: none;
  min-width: 160px;
  padding: 0;
  z-index: 1000;

  &:after {

    content: "";
    height: 0;
    left: 20px;
    position: absolute;
    top: 5px;
    width: 0;
  }

  ul:after {

    content: "";
    height: 0;
    left: -7px;
    position: absolute;
    top: 20px;
    width: 0;
  }

  li a {
    text-indent: 30px;
  }
  li li a {
    text-indent: 40px;
  }
  li > ul {
    margin: 0;
    padding: 0;
  }

  li:hover > ul,
  li.actif > ul {
    left: 100%;
    top: 23px;
  }

  ul li {
    border-radius: 0;
  }
}

/* fil darianne*/

.arianne {
  overflow: hidden;
  width: 100%;

  a {
    padding: .7em 1em;
    float: left;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    position: relative;

    &::before {
      left: -.5em;
      border-radius: 5px 0 0 5px;
    }

    &::after {
      right: -.5em;
      border-radius: 0 5px 5px 0;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1em;
      transform: skew(-10deg);
    }
  }

  li {
    float: left;
  }
}

nav.arianne ul {
  list-style-type: none;
  position: relative;
  top: 0;
  padding: 0 5px;
  margin: 10px 0;

  li {
    float: left;
    padding: 0 5px;
    list-style-type: none;

    &.fils-separation {
      padding: 5px;
    }

    a {
      text-decoration: none;
      font-size: 12px;

    }
  }
}

/*-----------------------------
    FICHE PRODUIT
-------------------------------*/

// On cache le titre de la page sur les fiches produit pour éviter le doublon de nom produit

.cmonsite-ficheproduit #bloc-fil-title {

  margin: 10px 0;

  h2, h1, .h2, .h1 {
    display: none;
  }
}

// Structure globale fiche produit

.template_fiche_produit {

  #fiche-produit {

    h3, .h3, h1, .h1 {
      margin-bottom: 10px;
    }

    .remise-produit {
      left: 0;
      right: auto;
      top: 50px;
      border-radius: 0;
      z-index: 100;
      font-weight: bold;
      width: auto;
      height: auto;
      padding: 15px 10px;
      font-size: 12px;
    }

    .wrap-images {
      box-sizing: border-box;
      box-shadow: 0 0 10px #444;

      &:hover {
        .previous, .bx-prev {
          left: 10px;
          opacity: 1;
        }

        .next, .bx-next {
          right: 10px;
          opacity: 1;
        }
      }

      .previous, .bx-prev {
        text-align: center;
        font-size: 0;
        opacity: 0;
        transition: all .1s ease;
        text-indent: 0;
        width: 30px;
        height: 30px;
        line-height: 32px;
        border-radius: 50%;
        left: -10px;

        &::before {
          content: "\f053";
          font-family: FontAwesome, sans-serif;
          font-size: 18px;
          display: inline-block;
          height: 100%;
          margin-left: -3px;
        }

        .fa {
          display: none;
        }
      }

      .next, .bx-next {
        text-align: center;
        font-size: 0;
        opacity: 0;
        transition: all .1s ease;
        text-indent: 0;
        width: 30px;
        height: 30px;
        line-height: 32px;
        border-radius: 50%;
        right: -10px;

        &::before {
          content: "\f054";
          font-family: FontAwesome, sans-serif;
          font-size: 18px;
          display: inline-block;
          height: 100%;
          margin-right: -3px;
        }

        .fa {
          display: none;
        }
      }

      .grande-image {
        box-sizing: border-box;
      }

      .previews {

        ul li {
          box-sizing: border-box;
        }
      }
    }

    .wrap-description {

      padding: 10px;
      box-sizing: border-box;
      box-shadow: 0 0 10px #444;

      .note-produit {
        margin: 20px 0 10px;
      }

      .declinaison-attribute-group label:first-child, .product_options-group label:first-child {
        margin-right: 15px;
      }

      .radio_unique {
        cursor: pointer;
        margin: 10px 10px 10px 0;
        border-radius: 0;
        float: none;
        box-shadow: none;
      }

      .radio_color {
        box-shadow: none;
        border-radius: 0;
        border: 2px solid #ccc;
      }

      .prix {
        padding: 0;
        display: flex;
        flex-direction: row;
        border: none;
        margin: 10px 0;

        .prixprod {
          padding: 7px;
          margin: 0 15px 0 0;
        }

        .oldprix {
          order: 2;
          line-height: 46px;
          text-decoration: line-through;
        }
        .prixmention {
          order: 3;
        }
      }

      select {
        min-height: 30px;
        padding: 5px;
      }

      .row {
        margin: 0 0 10px;
      }

      .pro-pannier {
        display: flex;
        flex-direction: column;
      }

      .declinaison {
        order: 1;
      }

      .bloc-quantite {
        order: 3;
        min-height: 36px;
        line-height: 36px;
        width: 100%;
        margin: 10px 0;

        .bt-quantity{
          height: auto;
        }
      }

      .ajouter-panier {
        &.block-price {
          order: 2;
        }
        &.block-addbasket {
          order: 4;
        }

        .triangle-ficheprod {
          display: none;
        }

        .addbasket {
          display: inline-block;
          width: auto;
          max-width: 100%;

          .fa-shopping-cart {
            margin-right: 10px;
          }
        }
      }

      .details-ficheprod a.button {
        display: inline-block;
        width: auto;
      }

      h1, .h1 {
        margin: 0;
      }
    }

    .bloc-description-detaille {
      box-sizing: border-box;
    }

    .wrap-details {
      margin: 20px 0;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 0 0 10px #444;
    }

    .wrap-details.bloc-avis-clients {
      .button {
        width: auto;

        .fa-comment {
          margin-right: 10px;
        }
      }
    }

    h4, .h4 {
      margin: 0;
      padding: 10px;
    }

    #addcomment {
      width: 100%;
    }

    .bloc-avis-clients {

      .moyenne_etoiles {
        margin: 0 15px;
      }

      .msg-informations {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 20px;

        .noteComment {
          float: right;
          font-size: 20px;
        }

        span {
          display: inline-block;
        }

        .time {
          margin-right: 10px;
          font-size: .9rem;
        }

        .msg-username {
          font-weight: bold;
        }
      }
    }
  }

  &.template_fiche_produit_1 #fiche-produit {

    .wrap-images {
      width: 34%;
      margin-right: 2%;
      padding: 10px;

      .grande-image {
        padding-bottom: 25px
      }
    }

    .wrap-description {

      h3, .h3 {
        margin: 10px 0;
      }

    }
  }

  // Affichage particulier template fiche produit 2

  &.template_fiche_produit_2 #fiche-produit {

    h1, .h1 {
      margin: 10px 0;
      font-size: 1.5rem;
    }

    .wrap-images {
      width: 63%;
      margin-bottom: 20px;

      .triangle-ficheprod {
        right: 0;
      }
      .remise-produit {
        right: -10px;
        left: auto;
        height: auto;
      }
    }

    .wrap-description {
      width: 35%;
      box-sizing: border-box;

      .produit_etiquette::after {
        display: none;
      }

      /*Passer au dessus de style fiche produit*/
      #produit-comment .commentFicheProd {
        padding-right: 0;
      }

      .details-ficheprod {
        display: none;
      }

      .wrap-details {
        box-shadow: none;
      }
    }

    #savoirplus {
      width: 63%;
    }

    .wrap-details {
      padding: 0;

      &.bloc-avis-clients {

        > .row {
          margin: 15px 0;
        }

        .msg-informations {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .grande-image {
      border: none;
    }

    .addbasket {
      margin: 12px 0 0 0;
      padding: 7px 15px;
      line-height: 22px;
      font-size: 1.4em;
    }

    .bloc-description-detaille {
      padding: 10px 0;

      h4, .h4 {
        padding-bottom: 10px;
      }

      p {
        margin: 10px 0;
        text-align: justify;
      }
    }

    .noteGlobale {
      box-sizing: border-box;
      padding: 10px;
    }

    #addcomment {
      padding: 0;
    }
  }

  // Affichage particulier template fiche produit 3

  &.template_fiche_produit_3 #fiche-produit {

    h1, .h1 {
      font-size: 1.8rem;
      margin: 20px 0 0;
      padding: 15px 0;
      position: relative;
      z-index: 5;
    }

    .wrap-images {
      margin-top: 0;

      .grande-image {
        border: none;
      }
    }

    .remise-produit {
      display: none;
    }

    .prix {
      order: 1;
      width: 100%;
      height: 50px;
      line-height: 50px;

      .oldprix, .prixprod {
        display: inline-block;
        width: auto;
        margin: 0 15px 0 0;
      }

      .prixprod {
        line-height: 36px;
      }

      .oldprix {
        line-height: 50px;
        text-decoration: line-through;
      }
    }

    .bloc-quantite {
      order: 2;
      width: 100%;
    }

    .ajouter-panier {
      order: 3;
    }

    .declinaison {
      order: 4;
    }

    .wrap-description {

      > .row {
        margin: 0;
      }

      .container-etiquette {
        margin-bottom: 10px;

        .produit_etiquette::after {
          display: none;
        }
      }

      .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
        width: auto;
        margin: 10px 0 0 120px;
        font-size: 1.4em;
        padding: 6px 15px;
        line-height: 26px;

        .fa-shopping-cart {
          margin-right: 10px;
        }
      }

      .pro-pannier .ajouter-panier {
        padding-top: 0;
      }

      .details-descr-com {

        .side-tabs {
          width: 100%;

          > a {
            width: calc(100% / 3);
            box-sizing: border-box;
            clear: none;
            display: inline-block;
            border-radius: 0;
            border-bottom: none;
            border-top: none;
            border-left: none;
            margin: 0;
            float: left;

            &:last-child {
              border-right: none;
            }
          }
        }

        .in-onglet {
          width: 100%;
          padding: 15px 15px 0;
          margin-left: 0;

          .wrap-details {
            padding: 0;
            margin: 0;
            box-shadow: none;
          }

          #addcomment {
            padding: 0;
            margin-top: 15px;
          }
        }
      }

      .sociaux {
        border-bottom: none;
      }
    }

    .bloc-avis-clients .noteGlobale,
    .bloc-avis-clients #addcomment {
      background: none;
    }
  }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > .button {
    flex: 0 1 auto;
    margin: 15px 0;
    display: inline-block;
  }

  > .button + .button {
    margin-left: 15px;
  }

}


.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}


/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/

.template_album {

  .list-photos {
    margin: 0 -0.66%;
  }

  .galerie:hover {

    .addcommentaires {
      transition: all .1s linear .1s;
    }
  }

  .addcommentaires {
    transition: none;
    height: 50px;
    width: 50px;
    font-size: 1rem;
    padding: 14px 0;
    text-align: center;
    border-radius: 50%;
    box-sizing: border-box;
    text-decoration: none;

    .fa-comments-o {
      margin-right: 3px;
    }
  }

  &.template_album_2 {

    .galerie {

      .commentP p {
        display: inline-block;
        font-size: 1rem;
        position: relative;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
      }

      img {
        transform: none;
      }
    }
  }

  &.template_album_3 {

    .addcommentaires {
      top: -50px;
    }
  }
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/
.template_livreor {

  .web:empty {
    display: none;
  }

  .buttonLivre {
    margin: 10px 0;
  }

  &.template_livreor_1 {

    .message_lo {
      box-shadow: 0 0 10px #444;
      border-radius: 0;

      .note {
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
  }

  &.template_livreor_2 {

    .IDLO h4, .IDLO .h4 {
      margin: 15px 0;
    }

    .message_lo {
      background: none;

      .blcFleche::before {
        border: none;
        margin-left: 8px;
      }

      .contenuMsgLO {
        border: none;
      }

      &:hover .contenuMsgLO, &:hover .blcFleche::before {
        box-shadow: 0 0 10px #444;
        border: none;
      }

      .note {
        margin: 0 0 0 10px;
      }
    }
  }

  &.template_livreor_3 {

    .IDLO h4, .IDLO .h4 {
      padding: 0;
      margin: 0 10px 15px 0;
    }

    .message_lo {
      box-shadow: 0 0 10px #444;

      .message p span {
        font-size: .8rem;
      }
    }

    .note {
      margin: 0;
    }

    .web {
      display: inline-block;

      &:empty {
        display: none;
      }
    }
  }
}

.livre_dor > h2,
.livre_dor > .h2 {
  text-align: center;
}

.message_lo {
  border-bottom: 1px solid;
  margin: 25px 0;
  border-radius: 5px 5px 5px 5px;

  h4, .h4 {
    float: left;
    font-size: 14px;
    padding: 9px;
  }

  .message {
    font-size: 12px;
    clear: both;
    min-height: 50px;
    padding: 0 12px;
  }

  .web {
    font-size: 12px;
    font-style: italic;
    padding: 4px 15px;

    a {
      text-decoration: none;
    }
  }

  .note {
    border: 1px solid;
    float: right;
    font-size: 18px;
    font-weight: bold;
    height: 25px;
    margin-top: 10px;
    overflow: hidden;
    text-align: center;
    padding: 0 15px;
    width: 60px;
    margin-right: 15px;
  }
}

.select_style i {
  padding: 0 10px;
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/
.template_boutique_all {

  .produits {
    box-sizing: border-box;
    padding: 10px;
    text-align: center;

    &:hover {
      box-shadow: 0 0 10px #444;
    }

    > p {
      margin: 10px 0;
      height: 30px;
    }

    .remise {
      position: absolute;
      right: 10px;
      top: 60px;
      left: auto;
      padding: 10px;
      z-index: 3;
    }

    .container-etiquette {
      position: relative;
      right: 0;
      left: 0;

      .produit_etiquette {
        left: 0;
        right: 0;
        position: absolute;
        width: auto;
      }
    }

    .productImageWrap img {
      box-sizing: border-box;
      padding-bottom: 20px;
      width: 100%;
    }

    .mask {
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    .zoom {
      opacity: 0;
      background: none;
      box-shadow: none;
      width: 100%;

      box-sizing: border-box;
      margin: 0;
      text-align: center;
      &:hover {
        opacity: 1;
        background: transparent;
      }

      // Bouton "plus de détails" au hover
      &::before {
        display: none;
      }

      .button{
        display: inline-block !important;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index:2;
        font-size: 12px;
        margin:0;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: .5;
      }
    }

    &:hover {

      .zoom {
        opacity: 1;
      }
    }

    .nomprod {
      margin: 10px 0;
      text-align: center;

      a {
        text-decoration: none;
        font-size: 1rem;
      }
    }

    .prix {
      display: flex;
      flex-direction: column;

      .prixprod {
        order: 1;
        margin: 5px 0;
      }

      .oldprix {
        order: 2;
        margin: 5px 0;
        text-decoration: line-through;
      }

      .prixmention {
        order: 3;
      }
    }

    .attr {
      width: 100%;
      text-align: center;

      select {
        height: 30px;
        width: auto;
        min-width: 120px;
        right: 0;
      }
    }

    .addbasket, .hors-stock {
      width: auto;
      display: inline-block;
      box-sizing: border-box;
      font-size: 1rem;
      padding: 10px 20px;
      float: none;
    }

    .hors-stock .inside-hors-stock {
      font-size: 1rem;
    }
  }

  &.template_boutique_accueil {

    &:hover .mask {
      background: none;
    }

    .mask .zoom {
      border-radius: 0;
      padding-bottom: 20px;
      height: calc(100% - 22px);
    }

    &.accueil_boutique_template_1 {
      $largeurFleches: 0px;
      $margin: 0px;
      $nbrProduit: 4;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);

      &:hover .bx-controls-direction {

        a.bx-prev {
          opacity: 1;
          left: 10px;
        }

        a.bx-next {
          opacity: 1;
          right: 10px;
        }
      }

      .bx-controls-direction {

        a.bx-prev {
          background: none;
          text-indent: 0;
          font-size: 0;
          text-decoration: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          padding: 5px;
          text-align: center;
          box-shadow: 0 0 3px #444;
          opacity: 0;
          left: 0;
          transition: all .1s;

          &::before {
            content: "\f053";
            font-family: fontAwesome, sans-serif;
            font-size: 20px;
            line-height: 27px;
            margin-left: -2px;
          }
        }

        a.bx-next {
          background: none;
          text-indent: 0;
          font-size: 0;
          text-decoration: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          padding: 5px;
          text-align: center;
          box-shadow: 0 0 3px #444;
          opacity: 0;
          right: 0;
          transition: all .1s;

          &::before {
            content: "\f054";
            font-family: fontAwesome, sans-serif;
            font-size: 20px;
            line-height: 27px;
            margin-right: -2px;
          }
        }
      }

      .produits:hover .mask .zoom {
        opacity: 1;
      }
    }
  }

  &.template_boutique {

    .container-etiquette {
      position: relative;

      h3, .h3 {
        padding: 5px;
      }
    }

    .productImageWrap {

      .zoom {
        border-radius: 0;
        bottom: 22px;
        height: auto;
      }
    }

    .productContentWrap {

      .attr {
        position: relative;
      }

      .addbasket, .hors-stock {
        float: none;
        box-sizing: border-box;
        display: inline-block;
        position: relative;
      }
    }

    &.template_boutique_1 {
      .produits{
        .attr{
          float:none;
          width: auto;
        }
      }
    }

    &.template_boutique_3 {
      .produits {

        .container-etiquette .produit_etiquette {
          right: 12px;
        }

        .remise {
          right: auto;
          left: 10px;
        }

        &.is_etiquette .nomprod {
          margin-top: 0;
        }

        .productImageWrap {

          .zoom {
            right: 12px;
            width: auto;
          }

          img {
            border-bottom: none;
            box-sizing: border-box;
            padding-right: 10px;
          }
        }

        .nomprod a {
          font-size: 1.4rem;
        }

        .prix {
          text-align: center;
          flex-direction: row;
          justify-content: center;
          .oldprix {
            line-height: 23px;
            margin-left: 10px;
            text-decoration: line-through;
          }
        }

        .attr {
          text-align: center;

          select {
            float: none;
          }
        }

        .addbasket, .hors-stock {
          float: left;
          bottom: auto;
        }

      }
    }

    &.template_boutique_4 {
      .productImageWrap {
        position: relative;
      }

      .container-etiquette {
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        margin-top: 0;
      }

      .prix {
        .oldprix {
          font-size: 0.8rem;
          text-decoration: line-through;
        }

        .prixprod {
          font-size: 1.3rem;
        }
      }

      .attr {
        box-sizing: border-box;

        select {
          left: 0;
        }
      }
    }

    &.template_boutique_5 {

      .produits {
        .productImageWrap {

          .zoom {
            bottom: 20px;
          }

          img {
            border: none;
          }
        }

        .remise {
          display: none;
        }
      }

      .attr {
        margin-top: 10px;
        select {
          float: none;
        }
      }
    }
  }
}

/*-----------------------------
    FORMULAIRE D'AJOUT DE MESSAGE SUR LE LIVRE D'OR
-------------------------------*/

.template_contact {
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px #444;

  h2, .h2 {
    padding: 10px;
  }

  &.template_contact_2 {

    .form-group {

      label {
        margin: 10px 0;
      }

      input[type="text"] {
        padding: 5px;
        transition: background .2s linear;
      }
    }

    input.button[type="submit"] {
      margin: 10px 0;
    }
  }

  &.template_contact_4 {
    .tmp4blocC {
      background: none;
      vertical-align: top;
      padding: 0 0 0 20px;

      h2, .h2 {
        margin-top: 0;
      }
    }
  }

  &.template_contact_5 {
    margin-top: -20px;
    background: none;
    padding: 0;
    box-shadow: none;

    .contenu-editable-contact {
      box-shadow: 0 0 10px #444;
      padding: 10px;
      box-sizing: border-box;
      margin: 20px 0;

      &:empty {
        padding: 0;
      }
    }

    .blocAdresse {
      box-shadow: 0 0 10px #444;
    }

    .blocForm {
      box-shadow: 0 0 10px #444;
    }

    input.button[type="submit"] {
      margin: 10px 0;
    }
  }
}

/*-----------------------------
    PAGE DE CONTACT
-------------------------------*/

/*-----------------------------
    PANIER
-------------------------------*/
.cmonsite-panier-2 {

  #nav-principal {
    display: flex;
    flex-direction: column;

    > ul {
      order: 2;
      width: 100%;
      max-width: none;
      z-index: 5;
    }

    .template-panier-2 {
      order: 1;
      border: none;
      width: 300px;
      max-width: none;

      #panier {
        padding: 0 10px;
      }

      #header-panier {
        font-size: 1.1rem;
        font-weight: bold;
      }

      #paniertable {
        height: 450px;
        max-height: none;
        overflow: inherit;
      }

      .fa-shopping-cart {
        font-size: 1.4rem;
        margin-right: 15px;
      }

      .count-live {
        left: 35px;
        top: 25px;
      }
    }
  }
}

.template-panier {
  #paniercommande .ajout_panier a {
    padding: 10px 15px;
    font-size: 1rem;
  }

  #paniervide {
    text-align: center;
  }

  .scrollPanier {
    max-height: 300px;

    .produit_panier .panier_prod_content {
      box-sizing: border-box;
    }
  }
  .scrollX > .viewport, .scrollY > .viewport {
    max-height: 300px;
  }

  &.template-panier-1 {

    #paniercontent {
      padding: 5px 0;
    }

    .scrollY > .viewport {
      max-height: 150px;
    }
  }

  &.template-panier-2 {
    z-index: 10;

    #paniercontent {
      left: 0;
      top: 44px;
      position: absolute;
      width: 300px;
      min-width: 0;
      border: none;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 5px;
      }
    }
  }

  &.template-panier-3 {
    width: 275px;
    position: absolute;
    top: 100%;
    float: none;
    left: 0;
    margin: 20px auto;

    #paniercontent {
      left: calc(100% + 13px);
      top: 0;
      border: none;

      @media #{$large-and-up} {
        top: 0;
        border: none;
        position: fixed;
        left: 300px;
        width: 500px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 5px;
      }

      #paniertable {
        overflow: inherit;
      }

      .scrollPanier {
        max-height: 300px;
        @media #{$large-and-up} {
          min-height: 300px;
        }
      }
    }
  }
}

/*-----------------------------
    PIED DE PAGE
-------------------------------*/
footer {
  max-width: 100%;
  text-align: center;
  z-index: 10;
  padding: 15px;
  box-sizing: border-box;

  .mention.links_p {
    text-align: center;
  }
}

.mention a {
  text-decoration: none;
  font-size: 10px;
}

.mention .links_p .footer_p {
  display: block;
  margin: 0 auto;
  text-align: center;
}

/*-----------------------------
    SLIDER
-------------------------------*/
.accueil_diaporama_template {
  width: 1200px;
  margin: 0 auto;
  padding: 10px;

  // Navigation du slider (boutons et fleches)

  .bx-wrapper {
    margin-bottom: 0;
  }

  &:hover {

    .previous, .next, .bx-prev, .bx-next {
      opacity: 1;
    }

    a.bx-prev {
      left: 30px;
    }

    a.bx-next {
      right: 30px;
    }
  }

  a.bx-prev {
    width: 45px;
    height: 45px;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    font-size: 0;
    opacity: 0;
    transition: all .1s ease;
    text-indent: 0;
    left: 0;

    &::before {
      content: "\f053";
      font-family: FontAwesome, sans-serif;
      font-size: 35px;
      line-height: 45px;
      margin-left: -5px;
      display: inline-block;
    }

    .fa {
      display: none;
    }
  }

  a.bx-next {
    width: 45px;
    height: 45px;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    font-size: 0;
    opacity: 0;
    transition: all .1s ease;
    text-indent: 0;
    right: 0;

    &::before {
      content: "\f054";
      font-family: FontAwesome, sans-serif;
      font-size: 35px;
      line-height: 45px;
      margin-right: -5px;
      display: inline-block;
    }

    .fa {
      display: none;
    }
  }

  // Position des éléments

  .slide .slide-text .content-slide-text {

    opacity: 0;
    transition: opacity .2s linear;

    > p {
      bottom: 20px;
      top: auto;
      left: 0;
      margin: 0 auto;
      right: auto;
      max-width: 60%;
      font-size: 1.1rem;
    }

    > a {
      top: auto;
      bottom: 20px;
      margin-bottom: 0;
      border-radius: 10px;
    }

    .addcommentaires ~ .slider-see-more {
      right: 105px;
    }
  }

  .slide:hover .content-slide-text {
    opacity: 1;
  }

  // Affichage particulier template slider 1

  &.accueil_diaporama_template_1 {
    margin: 40px auto;
    box-shadow: 0 0 10px #444;

    .bx-viewport, .slide {
      max-width: 1200px;
    }
  }

  // Affichage particulier template slider 2

  &.accueil_diaporama_template_2 {
    width: 100%;
    margin-bottom: 0;
    padding: 10px 0;

    .bx-wrapper .bx-pager {
      position: relative;
      padding: 0;
      bottom: auto;
    }

    .slide .slide-text .content-slide-text {

      > p {
        bottom: 45px;
        left: 0;
        right: auto;
      }

      > a {
        top: auto;
        bottom: 45px;
        padding: 20px;
        width: auto;
        font-size: 1.1rem;
      }

      .addcommentaires ~ .slider-see-more {
        right: 135px;
      }
    }
  }

  // Affichage particulier template slider 3

  &.accueil_diaporama_template_3 {
    margin: 15px auto;
    box-shadow: 0 0 10px #444;

    .bx-controls-auto {
      display: none;
    }

    #bx-pager {
      > a {
        opacity: 0.3;

        &.active, &:hover {
          opacity: 1;
        }
      }
    }
  }

  // Affichage particulier template slider 4
  &.accueil_diaporama_template_4 {
    margin: 0 auto;
    padding: 10px 0;
    width: 100%;
    box-shadow: 0 0 10px #444;

    .slide .slide-text .content-slide-text {

      > p {
        bottom: 20px;
        right: auto;
        left: 40px;
      }

      .addcommentaires ~ .slider-see-more {
        right: 125px;
        padding: 20px;
        box-sizing: border-box;
        width: auto;
      }
    }
  }

  // Affichage particulier template slider 5

  &.accueil_diaporama_template_5 {
    margin: 15px auto;
    box-shadow: 0 0 10px #444;
    padding: 10px;
    min-height: 0;

    .slide .slide-text .content-slide-text {

      > p {
        bottom: auto;
        top: 15px;
        right: 20px;
        left: auto;
      }

      > a {
        bottom: 15px;
        right: 20px;
      }

      .addcommentaires ~ .slider-see-more {
        right: 95px;
      }
    }
  }
  &.accueil_diaporama_template_6 {
    padding: 10px 0 0;
    min-height: 0;

    .slide .slide-text .content-slide-text {

      > p {
        bottom: 20px;
        right: auto;
        left: 40px;
      }

      .slider-see-more {
        right: 40px;
        bottom: auto;
        top: 20px;
        padding: 20px;
        box-sizing: border-box;
        width: auto;
      }
    }
  }
}

.wrapper.accueil_diaporama_template.accueil_diaporama_template_6{
  background: transparent !important;
  background-color: transparent !important;
}

#slider {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

#slider .bx-viewport {
  height: auto !important;
}

#slider ul {
  width: 100%;
  margin: 0;
}

#slider ul li {
  width: 25%;
  padding: 15px 30px;
  box-sizing: border-box;
}

#slider ul li img {
  height: 190px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  max-height: 190px;
  max-width: 100%;
}

#slider a {
  text-decoration: none;
  font-size: .8em;
}

#slider a:hover, .diaporama a:hover {
  text-decoration: underline;
}

#slider a.one_image {
  display: block;
}

#main-conteneur .bx-wrapper {
  .bx-pager {
    width: 100%;
    text-align: center;
  }
}

/*-----------------------------
    BLOG
-------------------------------*/

.apercu-article .bloc-article {
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px #444;
  margin-top: 0;
}

.blog-sidebar .blog-sidebar-bloc {
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px #444;
  margin-bottom: 15px;

  ul {
    padding: 0;

    li {
      box-sizing: border-box;
    }
  }
}

#wrapper {
  .single-article {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 10px #444;
    margin: 20px 0;
  }

  .one-column-blog .blog-sidebar {
    padding: 0;

    .blog-sidebar-bloc {
      width: 32%;

      &:not(:last-child) {
        margin-right: 2%;
      }
    }
  }
}

.template_boutique .boutique_header:empty,
.template_boutique .boutique_footer:empty,
.album-photo .galerie_header:empty,
.album-photo .galerie_footer:empty {
  padding: 0;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 95%;
  }

  .logo-carrier-none {
    width: auto;
  }

  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}


/* Commandes */

#order .commandes td{
  .button{
    margin:0 5px 0 0 !important;
  }
}
#order .commandes-details{
  > td > div{
    border-bottom:none;
  }
  .infosclient.s6{
    &, &:first-child{
      width: 100%;
      margin:10px 0 0;
    }
  }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 1520px) {

  .cmonsite-index #wrapper {
    margin: 0;
  }

  #wrapper {
    width: auto;
    margin: 15px;
  }

  .template_boutique_all{
    &.template_boutique_accueil{
      &.accueil_boutique_template_2{
        margin: 0 15px;
      }
    }
  }

  .accueil_diaporama_template {
    width: 100%;

    &.accueil_diaporama_template_1 {
      margin: 15px 15px 30px 15px;
      width: auto;

      .bx-viewport, .slide {
        max-width: 100%;
      }
    }

    &.accueil_diaporama_template_6 {
      padding: 15px 15px 0;
    }
  }

  .accueil_boutique_template_1 {
    width: auto;
    margin: 15px;

    .produits {
      .addbasket, .hors-stock {
        font-size: .8rem;
      }
    }
  }

  .page {
    margin: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .template_fiche_produit.template_fiche_produit_2 #fiche-produit .addbasket {
    margin: 12px 0 0 -20px;
  }
}

@media screen and (max-width: 1600px) {
  #block-flottant-search {
    display: none;
  }
}

@media #{$medium-and-down} {

  #cmonsite_translate_element {

  }

  .cmonsite-panier-2 {

    nav#nav-principal {
      display: block;

      .menu_burger {
        padding: 0;
      }
    }
  }

  .cmonsite-panier-2 {

    header {

      #blocContent {
        width: calc(100% - 130px);
      }

      #headercontent {
        z-index: 5;
      }
    }

    nav#nav-principal {
      z-index: 1;
    }

    #headercontent .container-slogan {
      max-width: calc(100% - 114px);
    }
  }

  header {
    position: absolute !important;
    width: 100%;
    height: auto;
    float: none;

    #blocContent {
      min-height: 72px;
      z-index: 5;
      width: calc(100% - 75px);
      float: left;
    }

    .logo {
      width: auto;
      padding: 0 15px;
      max-width: 20%;
      float: left;

      img {
        max-height: 42px;
      }
    }

    #headercontent {
      margin-bottom: 0;
      cursor: pointer;
      height: auto;

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .container-slogan {
        position: relative;
        box-sizing: border-box;
        width: auto;
        max-width: calc(100% - 362px);
        text-align: left;
        float: left;

        h1, .h1 {
          text-align: left;
          font-size: 1.3rem;
          line-height: 42px;
        }
      }
    }

    nav#nav-principal .template-panier.template-panier-2 {
      display: block;
      width: 42px;
      height: 42px;
      position: absolute;
      right: 72px;
      top: 0;

      #header-panier {
        width: 42px;
        height: 42px;
        top: 0;

        .count-live {
          left: 23px;
        }

        .fa-shopping-cart {
          vertical-align: top;
        }
      }

      #paniercontent {
        right: -75px;
        width: auto;
        min-width: 500px;
        left: auto;
        top: 57px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: auto;
          width: auto;
          height: 5px;
          right: 0;
          left: 0;
        }
      }
    }

    .template-panier.template-panier-3 {
      margin: 0 auto 15px;
      float: right;
      position: static;
      width: 236px;
      .connexion p {
        font-size: 12px
      }
      #header-panier {
        font-size: 12px;
      }
      #paniercontent {
        right: -76px;
        width: auto;
        min-width: 500px;
        left: auto;
        top: 56px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: auto;
          width: auto;
          height: 5px;
          right: 0;
          left: 0;
        }
      }
    }
  }

  .menu {
    position: absolute;
    bottom: 15px;
    width: 100%;
    z-index: 1;

    nav#nav-principal {
      padding-right: 15px;
      width: 100%;
      position: relative;
      box-sizing: border-box;

      .menu_burger {
        font-size: 0;
        height: 42px;
        width: 42px;
        display: block;
        text-align: center;
        float: right;
        cursor: pointer;

        &::before {
          content: "\f0c9";
          font-family: fontAwesome, sans-serif;
          display: block;
          font-size: 25px;
          line-height: 45px;
        }
      }

      > ul {
        position: absolute;
        left: 0;
        right: 0;
        top: 57px;
        height: auto;
        margin: 0 0 10px 0;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 5px;
          z-index: 5;
        }

        li {
          z-index: 1;
        }

        .dropDown {
          display: block;
          width: 50px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }
  }

  body .wrapper-content, body footer {
    width: 100%;
    float: none;
  }

  #minisite-sidebar {
    display: none;
  }

  .template_boutique_all.template_boutique_accueil.accueil_boutique_template_1 {
    $largeurFleches: 0px;
    $margin: 20px;
    $nbrProduit: 3;
    @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
  }

  .template_fiche_produit.template_fiche_produit_2 #fiche-produit {

    .wrap-images, .wrap-description, .wrap-details#savoirplus {
      width: 100%;
      margin-bottom: 20px;
    }

    .container-etiquette {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .accueil_diaporama_template .slide .slide-text .content-slide-text {
    opacity: 1;
  }

  header #headercontent .container-slogan {
    display: none;
  }

  .template-panier.template-panier-3 {

    .connexion {
      width: 50%;
      padding: 0 0 0 5px;

      p {
        border-right: 1px solid;
      }
    }

    #panier {
      position: relative;
      width: 50%;

      #header-panier {
        text-align: left;
      }

      .count-live {
        top: 20px;
        left: 20px;
        margin: 0;
      }
    }
  }
}

@media #{$small-and-down} {

  .template_boutique_all.template_boutique_accueil.accueil_boutique_template_1 {
    $largeurFleches: 0px;
    $margin: 0px;
    $nbrProduit: 1;
    @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
  }

  .template-panier-3 .paniertoggle .fa{
    right: 7px;
  }

  header .template-panier.template-panier-3 {
    right: 0;
  }

  .template_fiche_produit {

    &.template_fiche_produit_1 #fiche-produit .wrap-images {
      width: 100%;
      margin-bottom: 20px;
    }

    &.template_fiche_produit_2 #fiche-produit {
      .wrap-images {
        width: 100%;
        margin-bottom: 20px;
      }

      .wrap-description {
        width: 100%;
      }
    }
  }

  .template_boutique_all.template_boutique.template_boutique_3 .produits .productImageWrap img {
    border: none;
  }


  @import 'design/helper/responsive/mobile/mobile';

}

@media screen and (max-width: 500px) {
  header nav#nav-principal .template-panier.template-panier-2 #paniercontent,
  header .template-panier.template-panier-3 #paniercontent {
    min-width: 300px;
  }
}